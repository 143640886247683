const _vclick_options = {};
const handler = () => {
  const { className, callback } = _vclick_options;
  const el = document.querySelector(`.${className}`);
  const path = event.path || (event.composedPath && event.composedPath());
  const clickIsOutside = path
    ? path.indexOf(el) < 0
    : !el.contains(event.target);

  if (clickIsOutside) {
    return callback();
  }
};

const clickOutside = {
  bind(el, { value }, vnode) {
    if (!vnode.context && !el) return;
    const randomString = Math.random().toString(36).substring(4);
    const className = `v-click-${randomString}`;
    setTimeout(() => {
      el.classList.add(className);
      _vclick_options.className = className;
      _vclick_options.callback = value;

      document.addEventListener("click", handler);
    }, 0);
  },
  unbind() {
    document.removeEventListener("click", handler);
  },
};

export default function install(Vue) {
  Vue.directive("clickOutside", clickOutside);
}

export default [
  {
    path: "requests",
    name: "الطلبات",
    component: () => import("@/pages/Reviewer/Requests/index"),
    redirect: "requests/accounts",
    children: [
      {
        path: "accounts",
        name: "طلبات أنشاء حساب",
        component: () => import("@/pages/Reviewer/Requests/Account/index"),
      },
      {
        path: "loans",
        name: "طلبات التقديم على القروض",
        component: () => import("@/pages/Reviewer/Requests/Loan/index"),
      },
      {
        path: "credit-cards",
        name: "طلبات أصدار بطاقة",
        component: () => import("@/pages/Reviewer/Requests/Card/index"),
      },
    ],
  },
];

import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
    form: {
      id: null,
      image: null,
      name: null,
      start: null,
      end: null,
    },
  },
  mutations: {
    SET_ADS: (state, data) => Object.assign(state.form, data),
    STORE_ADS: (state, data) => state.list.unshift(data),
    SET_ADS_LIST: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    UPDATE_ADS: (state, data) => {
      state.list = state.list.filter((item) => item.id !== data.id);
      state.list.unshift(data);
    },
    DELETE_ADS: (state, data) => {
      const item = state.list.find((item) => item.id === data);
      state.list.splice(state.list.indexOf(item), 1);
    },
  },
  getters: {
    ads: (state) => {
      let ads = new FormData();
      ads.append("image", state.form.image);
      ads.append("end", state.form.end);
      ads.append("start", state.form.start);
      return ads;
    },
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(`admin/advertisements?page=${page}`);
      commit("SET_ADS_LIST", payload);
    },
    async show({ commit }, id) {
      const { payload } = await $http.get(`admin/advertisements/${id}`);
      commit("SET_ADS", payload);
    },
    async store({ getters }) {
      return await $http.post("admin/advertisements", getters.ads);
    },
    async update({ state, getters }) {
      return await $http.post(
        `admin/advertisements/${state.form.id}`,
        getters.ads
      );
    },
    async delete(_, id) {
      return await $http.delete(`admin/advertisements/${id}`);
    },
  },
};

/* eslint-disable prettier/prettier */
import { $http } from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        form: {
            ingoing: {
                about: null,
                procedures: null,
                documents: null,
                legal_framework: null,
            },
            outgoing: {
                about: null,
                procedures: null,
                documents: null,
                legal_framework: null,
            }
        }
    },
    mutations: {
        SET_FUNDING: (state, data) => {
            state.form.ingoing = data.ingoing ? data.ingoing : {
                about: null,
                procedures: null,
                documents: null,
                legal_framework: null,
            };
            state.form.outgoing = data.outgoing ? data.outgoing : {
                about: null,
                procedures: null,
                documents: null,
                legal_framework: null,
            }
        }
    },
    actions: {
        async get({ commit }, id) {
            const { payload } = await $http.get(`bank-admin/external-funding?type=${id}`);
            commit("SET_FUNDING", payload);
        },
        async update({ state }, id) {
            return await $http.post(`bank-admin/external-funding?type=${id}`, state.form);
        },
    }
};

import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
    form: {
      id: null,
      governate: null,
      address: null,
      phone: null,
      name: null,
      type: null,
      lat: null,
      lng: null,
    },
    governates: [
      { value: 0, text: "اربيل" },
      { value: 1, text: "الانبار" },
      { value: 2, text: "بابل" },
      { value: 3, text: "بغداد" },
      { value: 4, text: "البصرة" },
      { value: 5, text: "حلبجة" },
      { value: 6, text: "دهوك" },
      { value: 7, text: "القادسية" },
      { value: 8, text: "ديالى" },
      { value: 9, text: "ذي قار" },
      { value: 10, text: "سليمانية" },
      { value: 11, text: "صلاح الدين" },
      { value: 12, text: "كركوك" },
      { value: 13, text: "كربلاء" },
      { value: 14, text: "المثنى" },
      { value: 15, text: "ميسان" },
      { value: 16, text: "النجف" },
      { value: 17, text: "نينوى" },
      { value: 18, text: "واسط" },
    ],
  },
  mutations: {
    SET_BRANCH: (state, data) => Object.assign(state.form, data),
    SET_TYPE: (state, id) => (state.form.type = id),
    STORE_BRANCH: (state, data) => state.list.unshift(data),
    SET_BRANCHES: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    UPDATE_BRANCH: (state, data) => {
      state.list = state.list.filter((item) => item.id !== data.id);
      state.list.unshift(data);
    },
    DELETE_BRANCH: (state, data) => {
      const item = state.list.find((item) => item.id === data);
      state.list.splice(state.list.indexOf(item), 1);
    },
  },
  actions: {
    async get({ commit, state }, page) {
      const { payload } = await $http.get(
        `bank-admin/bank-branches?type=${state.form.type}?page=${page}`
      );
      commit("SET_BRANCHES", payload);
    },
    async store({ state }) {
      return await $http.post("bank-admin/bank-branches", state.form);
    },
    async update({ state }) {
      return await $http.put(
        `bank-admin/bank-branches/${state.form.id}`,
        state.form
      );
    },
    async delete(_, id) {
      return await $http.delete(`bank-admin/bank-branches/${id}`);
    },
  },
};

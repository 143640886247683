<template>
  <div id="page">
    <div class="page_head">
      <div class="right">
        <h3 v-if="title">{{ title }}</h3>
        <slot name="right"></slot>
      </div>
      <div class="left">
        <slot name="left"></slot>
      </div>
    </div>
    <div class="page_content">
      <slot name="content"></slot>
      <slot name="modals"></slot>
    </div>
    <div class="page_bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["title"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
#page {
  display: flex;
  flex: 1;
  border: 1px solid var(--grey-2);
  .page_head {
    align-items: center;
    height: 64px;
    background: #fff;
    padding: 0 32px;
    display: flex;
    .left {
      margin-right: auto;
    }
    .right {
      margin-left: auto;
    }
    h3 {
      color: var(--dark);
      font-weight: 500;
      font-size: 18px;
      margin-top: 0;
    }
  }
  .page_bottom {
    margin: auto;
  }
}
.page_content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  padding: 32px;
}

.pagination {
  margin-bottom: 0;
  padding: 8px;
}
</style>

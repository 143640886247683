import Store from "@/store/index";
import Router from "@/router/index";
import axios from "axios";

let config = {
  baseURL: process.env.VUE_APP_BASE_URL,
};

const _axios = axios.create(config);
_axios.interceptors.request.use(
  async function (config) {
    let source = await axios.CancelToken.source();
    config.cancelToken = source.token;
    Store.dispatch("loader/add", { url: config.url, cancelToken: source });
    return config;
  },
  function (error) {
    Store.dispatch("loader/remove", error.response.config.url);
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    Store.dispatch("loader/remove", response.config.url);
    return response.data;
  },
  function (error) {
    if (error.response)
      Store.dispatch("loader/remove", error.response.config.url);
    if (error.response.status === 401) return Router.push("/logout");
    return Promise.reject(error);
  }
);

export const $http = _axios;

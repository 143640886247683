import { $http } from "@/plugins/axios";
import articles from "./modules/articles";
import news from "./modules/news";
import banks from "./modules/banks";
export default {
  namespaced: true,
  modules: {
    articles,
    news,
    banks,
  },
  state: {
    about: null,
    conditions: null,
    advertisements: null,
  },
  mutations: {
    SET_ABOUT: (state, data) => (state.about = data.text),
    SET_CONDITION: (state, data) => (state.conditions = data.text),
    SET_ADVERTISEMENTS: (state, data) => (state.advertisements = data),
  },
  actions: {
    async about({ commit }) {
      const { payload } = await $http.get("visitor/about");
      commit("SET_ABOUT", payload);
    },
    async conditions({ commit }) {
      const { payload } = await $http.get("visitor/conditions");
      commit("SET_CONDITION", payload);
    },
    async advertisements({ commit }) {
      const { payload } = await $http.get("visitor/advertisements");
      commit("SET_ADVERTISEMENTS", payload);
    },
  },
};

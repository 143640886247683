/* eslint-disable prettier/prettier */
import { $http } from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        content: null,
        form: {
            text: null,
        }
    },
    mutations: {
        SET_CONDITION: (state, data) => (state.content = data.text),
        SET_TEXT:(state, data) => (state.form.text = data.text)
    },
    actions: {
        async get({ commit }) {
            const { payload } = await $http.get("admin/conditions");
            commit("SET_CONDITION", payload);
            commit("SET_TEXT", payload);
        },
        async update({ state }) {
            return await $http.post("admin/conditions", state.form);
        },
    }
};

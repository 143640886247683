<template>
  <div>
    <div id="head">
      <div class="right">
        <h4>{{ title }}</h4>
      </div>
      <div class="left">
        <slot name="actions"></slot>
      </div>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>
<style lang="scss" scoped>
#head {
  display: flex;
  margin-bottom: 16px;

  .left {
    margin-right: auto;
  }
  .right {
    margin-left: auto;
  }

  h3 {
    color: var(--dark);
    font-weight: 500;
  }
}
</style>

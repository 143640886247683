import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
    form: {
      id: null,
      image: null,
      name: null,
      phone: null,
      email: null,
      bank_id: null,
      password: null,
    },
  },
  mutations: {
    SET_ADMIN: (state, data) => {
      Object.assign(state.form, data);
      state.form.bank_id = data.bank?.id;
    },
    STORE_ADMIN: (state, data) => state.list.unshift(data),
    SET_ADMINS: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    UPDATE_ADMIN: (state, data) => {
      state.list = state.list.filter((item) => item.id !== data.id);
      state.list.unshift(data);
    },
    DELETE_ADMIN: (state, data) => {
      const item = state.list.find((item) => item.id === data);
      state.list.splice(state.list.indexOf(item), 1);
    },
  },
  getters: {
    user: (state) => {
      let user = new FormData();
      user.append("name", state.form.name);
      user.append("phone", state.form.phone);
      user.append("email", state.form.email);
      user.append("password", state.form.password);
      user.append("bank_id", state.form.bank_id);
      user.append("image", state.form.image);
      return user;
    },
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(`admin/banks-admins?page=${page}`);
      commit("SET_ADMINS", payload);
    },
    async show({ commit }, id) {
      const { payload } = await $http.get(`admin/banks-admins/${id}`);
      commit("SET_ADMIN", payload);
    },
    async store({ getters }) {
      return await $http.post("admin/banks-admins", getters.user);
    },
    async update({ state, getters }) {
      return await $http.post(
        `admin/banks-admins/${state.form.id}`,
        getters.user
      );
    },
    async delete(_, id) {
      return await $http.delete(`admin/banks-admins/${id}`);
    },
  },
};

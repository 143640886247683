import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
    form: {
      id: null,
      image: null,
      name: null,
      type: null,
      advantages_and_conditions: null,
      fees_and_commissions: null,
    },
    types: [
      { value: 0, text: "البطاقات المدينة" },
      { value: 1, text: "بطاقات الدفع المسبق" },
      { value: 2, text: "البطاقات الدائنة" },
      { value: 3, text: "بطاقات الهدية" },
    ],
  },
  mutations: {
    SET_CARD: (state, data) => Object.assign(state.form, data),
    STORE_CARD: (state, data) => state.list.unshift(data),
    SET_CARDS: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    UPDATE_CARD: (state, data) => {
      state.list = state.list.filter((item) => item.id !== data.id);
      state.list.unshift(data);
    },
    DELETE_CARD: (state, data) => {
      const item = state.list.find((item) => item.id === data);
      state.list.splice(state.list.indexOf(item), 1);
    },
  },
  getters: {
    card: (state) => {
      let card = new FormData();
      card.append("image", state.form.image);
      card.append("name", state.form.name);
      card.append("type", state.form.type);
      card.append(
        "advantages_and_conditions",
        state.form.advantages_and_conditions
      );
      card.append("fees_and_commissions", state.form.fees_and_commissions);
      return card;
    },
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(`bank-admin/cards?page=${page}`);
      commit("SET_CARDS", payload);
    },
    async show({ commit }, id) {
      const { payload } = await $http.get(`bank-admin/cards/${id}`);
      commit("SET_CARD", payload);
    },
    async store({ getters }) {
      return await $http.post("bank-admin/cards", getters.card);
    },
    async update({ state, getters }) {
      return await $http.post(
        `bank-admin/cards/${state.form.id}`,
        getters.card
      );
    },
    async delete(_, id) {
      return await $http.delete(`bank-admin/cards/${id}`);
    },
  },
};

/* eslint-disable prettier/prettier */
import { $http } from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        form: {
            individual: null,
            companies: null,
        }
    },
    mutations: {
        SET_ACCOUNT: (state, data) => {
            state.form.individual = data.individual.text;
            state.form.companies = data.companies.text
        }
    },
    actions: {
        async get({ commit }, id) {
            const { payload } = await $http.get(`bank-admin/deposit-accounts?account_type=${id}`);
            commit("SET_ACCOUNT", payload);
        },
        async update({ state }, id) {
            return await $http.post(`bank-admin/deposit-accounts?account_type=${id}`, state.form);
        },
    }
};

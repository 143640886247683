/* eslint-disable no-console */
import $alert from "sweetalert2";
export const clearObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    obj[key] = null;
  });
  return obj;
};

export const showAlertWithConfirm = (
  title,
  text = "لا يمكن التراجع عن هذه الخطوة",
  confirmButtonText = "تأكيد",
  cancelButtonText = "الغاء"
) => {
  return $alert.fire({
    title,
    text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#080916",
    cancelButtonColor: "#d33",
    confirmButtonText,
    cancelButtonText,
  });
};

export const successAlert = (title = "تمت العملية بنجاح", text) => {
  return $alert.fire({
    title,
    text,
    icon: "success",
    showConfirmButton: false,
    timer: 1700,
  });
};

export const errorAlert = (title = "هنالك مشكلة", text) => {
  return $alert.fire({
    title,
    text,
    icon: "error",
    showConfirmButton: false,
    timer: 1700,
  });
};

export const clearState = ({ state }) => {
  // get all state keys
  Object.keys(state).forEach((key) => {
    // get all proprietes from current state
    Object.keys(state[key]).forEach((item) => {
      // get current state propriety value
      const currentItem = state[key][item];
      // if state is array make it empty array
      if (Array.isArray(currentItem)) return (state[key][item] = []);
      // if state is object make it empty object
      if (typeof currentState === "object") return (state[key][item] = {});
      // if state is string or number make it null
      return (state[key][item] = null);
    });
  });
};

export const decodeToken = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const ImageURL = (image) => {
  var images = require.context("@/assets/", false, /\.jpg$/);
  return images("./" + image);
};

import Vue from "vue";
import VueRouter from "vue-router";
import systemAdminRoute from "./children/admin";
import bankAdminRoute from "./children/banks";
import reviewerRoute from "./children/reviewer";
import guestRoute from "./children/guest";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "مصرفنا ",
    component: () => import("@/layout/website/index"),
    redirect: "/home",
    children: guestRoute,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/login"),
  },
  {
    path: "/admin",
    name: "مصرفنا",
    component: () => import("@/layout/dashboard"),
    meta: {
      middleware: "Admin",
      authorization: true,
    },
    redirect: "/admin/statistics",
    children: systemAdminRoute,
  },
  {
    path: "/bank",
    name: " مصرفنا",
    component: () => import("@/layout/dashboard"),
    meta: {
      middleware: "BankAdmin",
      authorization: true,
    },
    redirect: "/bank/statistics",
    children: bankAdminRoute,
  },
  {
    path: "/reviewer",
    name: " مصرفنا ",
    component: () => import("@/layout/dashboard"),
    meta: {
      middleware: "BankReviewer",
      authorization: true,
    },
    redirect: "/reviewer/requests",
    children: reviewerRoute,
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      localStorage.clear();
      sessionStorage.clear();
      next("/login");
    },
  },
  {
    path: "/redirect",
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((from, to, next) => {
  Store.dispatch("loader/cancelAll");
  next();
});

export default router;

<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="randomName"
    :name="label"
    class="form-group"
    :disabled="disabled"
    :rules="rules"
    tag="div"
    mode="eager"
  >
    <label v-if="label && !hideLabel" :for="randomName">{{ label }}</label>
    <b-form-datepicker
      :id="randomName"
      v-model="field"
      v-bind="labels[locale] || {}"
      :locale="locale"
      :readonly="readonly"
      :date-format-options="{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }"
      :class="{ 'form-control w-100': true, 'is-invalid': errors.length }"
    ></b-form-datepicker>
    <small class="text-danger">{{ errors[0] }}</small>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import fieldMixins from "@/mixins/fieldMixins";

export default {
  name: "VDateField",
  components: {
    ValidationProvider,
  },
  mixins: [fieldMixins],
  props: {
    label: String,
    hideLabel: Boolean,
  },
  data() {
    return {
      locale: "ar-EG",
      labels: {
        "ar-EG": {
          weekdayHeaderFormat: "narrow",
          labelPrevDecade: "العقد السابق",
          labelPrevYear: "العام السابق",
          labelPrevMonth: "الشهر السابق",
          labelCurrentMonth: "الشهر الحالي",
          labelNextMonth: "الشهر المقبل",
          labelNextYear: "العام المقبل",
          labelNextDecade: "العقد القادم",
          labelToday: "اليوم",
          labelSelected: "التاريخ المحدد",
          labelNoDateSelected: "لم يتم اختيار تاريخ",
          labelCalendar: "التقويم",
          labelNav: "الملاحة التقويم",
          labelHelp: "استخدم مفاتيح المؤشر للتنقل في التواريخ",
        },
      },
    };
  },
  computed: {
    isDisabled() {
      if (typeof this.disabled === "function") return this.disabled();
      return this.disabled;
    },
    field: {
      get() {
        return this.value ? this.value.split("/").reverse().join("/") : null;
      },
      set(value) {
        // eslint-disable-next-line no-console
        this.$emit("input", value);
      },
    },
    randomName() {
      return Math.random().toString(36).substring(7);
    },
  },
  watch: {
    value() {
      this.field = this.value;
    },
  },
};
</script>

<style lang="scss">
.menu__content {
  z-index: 9999 !important;
}
.datepicker__input-icon {
  margin-top: 6px;
}
.datepicker__input-wrapper {
  padding-right: 5px;
}
</style>

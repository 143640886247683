/* eslint-disable prettier/prettier */
import { $http } from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        form: {
            text: null,
        }
    },
    mutations: {
        SET_TEXT:(state, data) => (state.form.text = data.text)
    },
    actions: {
        async get({ commit }, id) {
            const { payload } = await $http.get(`bank-admin/other-services?type=${id}`);
            commit("SET_TEXT", payload);
        },
        async update({ state }, id) {
            return await $http.post(`bank-admin/other-services?type=${id}`, state.form);
        },
    }
};

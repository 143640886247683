import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
  },
  mutations: {
    SET_REQUESTS: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    DELETE_USER: (state, data) => {
      const item = state.list.find((item) => item.id === data);
      state.list.splice(state.list.indexOf(item), 1);
    },
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(
        `bank-reviewer/loans-requests?page=${page}`
      );
      commit("SET_REQUESTS", payload);
    },
    async delete(_, id) {
      return await $http.delete(`bank-reviewer/loans-requests/${id}`);
    },
  },
};

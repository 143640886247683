import accounts from "./modules/accounts";
import loans from "./modules/loans";
import cards from "./modules/cards";

export default {
  namespaced: true,
  modules: {
    accounts,
    loans,
    cards,
  },
};

<template>
  <b-card class="my-3 p-2" header-bg-variant="transparent">
    <template #header>
      <div class="d-flex justify-content-between">
        <span>{{ title }} {{ type ? "خلال الشهر" : "خلال السنة" }} </span>
        <b-button-group class="mx-1">
          <b-button
            :variant="type ? 'info' : 'outline-info'"
            size="sm"
            @click="type = true"
            >شهر</b-button
          >
          <b-button
            :variant="!type ? 'info' : 'outline-info'"
            size="sm"
            @click="type = false"
            >السنة</b-button
          >
        </b-button-group>
      </div>
    </template>
    <apexchart
      height="400"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </b-card>
</template>

<script>
export default {
  props: {
    title: String,
    data: Object,
  },
  data() {
    return {
      type: true,
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "area",
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
          selection: {
            enabled: true,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        xaxis: {
          categories: this.type ? this.data.month?.date : this.data.year?.date,
        },
        legend: {
          show: false,
        },
      };
    },
    series() {
      return [
        {
          name: this.type ? " خلال الشهر " : " خلال السنة ",
          data: (this.type ? this.data.month?.data : this.data.year?.data) ?? [
            0, 0, 0, 0, 0,
          ],
        },
      ];
    },
  },
};
</script>

<style></style>

<template>
  <b-col lg="4" md="6">
    <div class="mb-3 bg-grey rounded p-5 text-center">
      <b-icon :icon="item.icon" variant="info" scale="2.5"></b-icon>
      <h5 class="text-secondary my-3">{{ item.name }}</h5>
      <p class="text-grey">{{ item.description }}</p>
    </div>
  </b-col>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>

<style lang="scss" scoped>
.bg-grey {
  background-color: #edf4fc !important;
}
.col-md-6,
.col-lg-4 {
  transition: 0.3s ease !important;
}
.col-md-6:hover,
.col-lg-4:hover {
  transform: scale(1.1);
}
</style>

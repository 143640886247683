export default [
  {
    path: "statistics",
    name: "الأحصائيات",
    component: () => import("@/pages/Admin/Statistics/index"),
  },
  {
    path: "users",
    name: "المستخدمين",
    component: () => import("@/pages/Admin/Users/index"),
    redirect: "users/managments",
    children: [
      {
        path: "managments",
        name: "الأدارة",
        component: () => import("@/pages/Admin/Users/Managments/index"),
      },
      {
        path: "bank",
        name: "مسؤولي المصارف",
        component: () => import("@/pages/Admin/Users/BankAdmins/index"),
      },
      {
        path: "guest",
        name: "الزائرين",
        component: () => import("@/pages/Admin/Users/Guest/index"),
      },
    ],
  },
  {
    path: "banks",
    name: "المصارف",
    component: () => import("@/pages/Admin/Banks/index"),
  },
  {
    path: "posts",
    name: "المنشورات",
    component: () => import("@/pages/Admin/Posts/index"),
    redirect: "posts/ads",
    children: [
      {
        path: "ads",
        name: "الأعلانات",
        component: () => import("@/pages/Admin/Posts/Ads/index"),
      },
      {
        path: "articles",
        name: "المقالات",
        component: () => import("@/pages/Admin/Posts/Articles/index"),
      },
      {
        path: "news",
        name: "الأخبار",
        component: () => import("@/pages/Admin/Posts/News/index"),
      },
    ],
  },
  {
    path: "about",
    name: "حول مصرفنا",
    component: () => import("@/pages/Admin/About/index"),
  },
  {
    path: "conditions",
    name: "الشروط والأحكام",
    component: () => import("@/pages/Admin/ConditionsAndRules/index"),
  },
];

<template>
  <b-card class="border-0 shadow overflow-hidden h-100">
    <div class="d-flex flex-column">
      <img :src="item.image" id="imgPost" height="250" />
      <h6 class="my-3">{{ item.title }}</h6>
      <div class="d-flex flex-row my-2">
        <b-icon icon="clock" variant="info"></b-icon>
        <small class="text-grey mr-2">{{
          new Date(item.created_at).toISOString().slice(0, 10)
        }}</small>
      </div>
      <div id="post-content" v-html="item.content"></div>
      <h6 class="text-info mt-2" style="cursor: pointer" @click="view()">
        المزيد >>
      </h6>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    item: Object,
    category: Number,
  },
  methods: {
    view() {
      this.category === 1 ? this.viewArticles() : this.viewNews();
    },
    viewArticles() {
      this.$router.push("/details/" + this.item.id);
    },
    viewNews() {
      this.$router.push("/blog/" + this.item.id);
    },
  },
};
</script>

<style scoped>
#post-content {
  height: 45px;
  overflow: hidden;
  color: black;
  font-weight: lighter !important;
}
#imgPost {
  transition: 0.3s ease !important;
}
.card:hover #imgPost {
  transform: scale(1.1);
}
</style>

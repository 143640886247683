import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
    form: {
      id: null,
      image: null,
      name: null,
      phone: null,
      email: null,
      bank_id: null,
      password: null,
      role: null,
    },
    roles: [
      { value: 0, text: "مسؤول المصرف" },
      { value: 1, text: "مسؤول الطلبات" },
    ],
  },
  mutations: {
    SET_USER: (state, data) => Object.assign(state.form, data),
    STORE_USER: (state, data) => state.list.unshift(data),
    SET_USERS: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    UPDATE_USER: (state, data) => {
      state.list = state.list.filter((item) => item.id !== data.id);
      state.list.unshift(data);
    },
    DELETE_USER: (state, data) => {
      const item = state.list.find((item) => item.id === data);
      state.list.splice(state.list.indexOf(item), 1);
    },
  },
  getters: {
    user: (state) => {
      let user = new FormData();
      user.append("name", state.form.name);
      user.append("phone", state.form.phone);
      user.append("email", state.form.email);
      user.append("password", state.form.password);
      user.append("image", state.form.image);
      user.append("role", state.form.role == 0 ? "BankAdmin" : "BankReviewer");
      return user;
    },
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(`bank-admin/users?page=${page}`);
      commit("SET_USERS", payload);
    },
    async show({ commit }, id) {
      const { payload } = await $http.get(`bank-admin/users/${id}`);
      commit("SET_USER", payload);
    },
    async store({ getters }) {
      return await $http.post("bank-admin/users", getters.user);
    },
    async update({ state, getters }) {
      return await $http.post(
        `bank-admin/users/${state.form.id}`,
        getters.user
      );
    },
    async delete(_, id) {
      return await $http.delete(`bank-admin/users/${id}`);
    },
  },
};

export default [
  {
    path: "/",
    name: "/",
    component: () => import("@/pages/Website/Home/index"),
  },
  {
    path: "articles",
    name: " المقالات",
    component: () => import("@/pages/Website/Articles/index"),
  },
  {
    path: "news",
    name: " الأخبار",
    component: () => import("@/pages/Website/News/index"),
  },
  {
    path: "details/:id",
    name: "تفاصيل المقاله",
    props: true,
    component: () => import("@/pages/Website/Articles/details"),
  },
  {
    path: "blog/:id",
    name: "تفاصيل الخبر",
    props: true,
    component: () => import("@/pages/Website/News/details"),
  },
  {
    path: "service",
    name: "الخدمات",
    component: () => import("@/pages/Website/Services/index"),
  },
  {
    path: "description/:type",
    params: true,
    name: "عرض الخدمة",
    component: () => import("@/pages/Website/Services/description"),
  },
  {
    path: "banks",
    name: " المصارف",
    component: () => import("@/pages/Website/Banks/index"),
  },
  {
    path: "about",
    name: "عن مصرفنا",
    component: () => import("@/pages/Website/About/index"),
  },
];

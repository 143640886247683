import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
    form: {
      id: null,
      image: null,
      title: null,
      content: null,
    },
  },
  mutations: {
    SET_ARTICLE: (state, data) => Object.assign(state.form, data),
    STORE_ARTICLE: (state, data) => state.list.unshift(data),
    SET_ARTICLES: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    UPDATE_ARTICLE: (state, data) => {
      state.list = state.list.filter((item) => item.id !== data.id);
      state.list.unshift(data);
    },
    DELETE_ARTICLE: (state, data) => {
      const item = state.list.find((item) => item.id === data);
      state.list.splice(state.list.indexOf(item), 1);
    },
  },
  getters: {
    article: (state) => {
      let article = new FormData();
      article.append("image", state.form.image);
      article.append("title", state.form.title);
      article.append("content", state.form.content);
      return article;
    },
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(`admin/articles?page=${page}`);
      commit("SET_ARTICLES", payload);
    },
    async show({ commit }, id) {
      const { payload } = await $http.get(`admin/articles/${id}`);
      commit("SET_ARTICLE", payload);
    },
    async store({ getters }) {
      return await $http.post("admin/articles", getters.article);
    },
    async update({ state, getters }) {
      return await $http.post(
        `admin/articles/${state.form.id}`,
        getters.article
      );
    },
    async delete(_, id) {
      return await $http.delete(`admin/articles/${id}`);
    },
  },
};

import Vue from "vue";
import Page from "./dashboard/VContent.vue";
import VTextField from "./dashboard/FormGenerator/Fields/VTextField";
import VDateField from "./dashboard/FormGenerator/Fields/VDateField";
import VSelectField from "./dashboard/FormGenerator/Fields/VSelectField";
import VFileField from "./dashboard/FormGenerator/Fields/VFileField";
import VTextArea from "./dashboard/FormGenerator/Fields/VTextArea";
import Head from "./dashboard/Head.vue";
import FormGenerator from "./dashboard/FormGenerator/FormGenerator.vue";
import VForm from "./dashboard/VForm";
import VTime from "./dashboard/VTime/index";
import VTextEditor from "./dashboard/FormGenerator/Fields/VTextEditor.vue";
// Statistics
import VStatisticsCrad from "./dashboard/Statistics/VStatisticsCrad.vue";
import VBarChart from "./dashboard/Statistics/VBarChart.vue";
import VLineChart from "./dashboard/Statistics/VLineChart.vue";
// Website
import VServiceCard from "./website/VServiceCard.vue";
import VDownloadMobile from "./website/VDownloadMobile";
import VLatestBlogs from "./website/Blog/VLatestBlogs";
import VBlog from "./website/Blog/VBlog";

// Body
Vue.component("VContent", Page);
Vue.component("Head", Head);
// Form
Vue.component("FormGenerator", FormGenerator);
Vue.component("VForm", VForm);
Vue.component("VTextField", VTextField);
Vue.component("VDateField", VDateField);
Vue.component("VSelectField", VSelectField);
Vue.component("VFileField", VFileField);
Vue.component("VTextArea", VTextArea);
Vue.component("VTime", VTime);
Vue.component("VTextEditor", VTextEditor);
// Statistics
Vue.component("VStatisticsCrad", VStatisticsCrad);
Vue.component("VBarChart", VBarChart);
Vue.component("VLineChart", VLineChart);
// Website
Vue.component("VServiceCard", VServiceCard);
Vue.component("VLatestBlogs", VLatestBlogs);
Vue.component("VDownloadMobile", VDownloadMobile);
Vue.component("VBlog", VBlog);

import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
    form: {
      id: null,
      image: null,
      title: null,
      content: null,
      created_at: null,
    },
  },
  mutations: {
    SET_NEWS_LIST: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    SET_NEWS: (state, data) => (state.form = data),
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(`visitor/news?page=${page ?? 1}`);
      commit("SET_NEWS_LIST", payload);
    },
    async getByID({ commit }, id) {
      const { payload } = await $http.get(`visitor/news/${id}`);
      commit("SET_NEWS", payload);
    },
  },
};

import { $http } from "@/plugins/axios";
import users from "./modules/users";
import admins from "./modules/admins";
import guest from "./modules/guest";
import banks from "./modules/banks";
import ads from "./modules/ads";
import articles from "./modules/articles";
import news from "./modules/news";
import about from "./modules/about";
import conditions from "./modules/conditions";

export default {
  namespaced: true,
  modules: {
    users,
    admins,
    guest,
    banks,
    ads,
    articles,
    news,
    about,
    conditions,
  },
  state: {
    statistics: {
      count: {},
      banks_visits: {},
      visitors_registration: {},
      last_visitors: [],
    },
  },
  mutations: {
    SET_STATISTICS: (state, data) => (state.statistics = data),
  },
  actions: {
    async get({ commit }) {
      const { payload } = await $http.get(`admin/statistics`);
      commit("SET_STATISTICS", payload);
    },
  },
};

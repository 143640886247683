import Vue from "vue";
import router from "./router";
import store from "./store";
import "@/components/index";
import "@/plugins/index";
import auth from "@/plugins/auth/index";
Vue.use(auth, {
  storage: { localstorage: true, key: "token" },
  strategy: { role: "role" },
  router,
});

import VClickOutside from "./directives/VClickOutside";
Vue.use(VClickOutside);

Vue.config.productionTip = false;

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-IQ", {
    style: "currency",
    currency: "IQD",
  });
  return formatter.format(value);
});

new Vue({
  router,
  store,
  render: (h) => h("router-view"),
}).$mount("#app");

import { cloneObject, decodeToken } from "../uitls/main";
import { $http } from "@/plugins/axios";

export default class Auth {
  options = {
    storage: {
      localstroage: true,
      sessionstorage: false,
      key: "token",
    },
    strategy: {
      role: "FeRole",
      privileges: "privileges",
    },
    loginPath: "/login",
    authRedirect: "/redirect",
  };
  constructor(router, options) {
    this.$router = router;
    cloneObject(this.options, options);
  }

  /**
   * get the storage the used to save the data
   * @returns {Storage}
   * @private
   */
  get _storage() {
    return this.options.storage.localstroage ? localStorage : sessionStorage;
  }

  /**
   *
   * @returns {string}
   * @private
   */
  get _token() {
    return this._storage[this.options.storage.key];
  }

  /**
   *
   * @returns {object}
   * @private
   */
  get _tokenPayload() {
    return this._token ? decodeToken(this._token) : null;
  }

  get userRole() {
    return JSON.parse(localStorage.getItem("role"));
  }

  get redirectPath() {
    const routes = this.$router.options.routes;
    const redirectRoute = routes
      .map((e) => (e.children ? [e, e.children] : e))
      .flat(1)
      .find((r) => r.meta?.middleware === this.userRole);
    return redirectRoute.name
      ? { name: redirectRoute.name }
      : { path: redirectRoute.path };
  }

  routeMiddleware(route, parent = false) {
    if (parent) {
      return route.matched[0].meta.middleware;
    }
    return route.meta.middleware;
  }

  routeAuthorization(route) {
    if (route.matched.length) {
      return route.matched[0].meta.authorization;
    }
    return route.meta.authorization;
  }

  secure() {
    this.$router.beforeEach((to, from, next) => {
      // Login
      if (
        [this.options.loginPath, this.options.authRedirect].includes(
          to.fullPath
        ) &&
        !!this._token
      )
        return this.$router.push(this.redirectPath);
      // Auth Route without Login
      if (this.routeAuthorization(to) && !this._token)
        return this.$router.push("/login");
      // Authorization
      if (this.routeAuthorization(to)) {
        $http.defaults.headers.Authorization = `Bearer ${this._token}`;
      } else {
        delete $http.defaults.headers.common["Authorization"];
      }
      // Route
      if (this.routeMiddleware(to)) {
        return this.routeMiddleware(to) === this.userRole
          ? next()
          : next(this.redirectPath);
      } else if (this.routeMiddleware(to, true)) {
        return this.routeMiddleware(to, true) === this.userRole
          ? next()
          : next(this.redirectPath);
      }
      next();
    });
  }
}

import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

window.Apex.chart = {
  locales: [
    {
      name: "ar",
      options: {
        months: [
          "كانون الثاني",
          "شباط",
          "آذار",
          "نيسان",
          "أيار",
          "حزيران",
          "تموز",
          "آب",
          "أيلول",
          "تشرين الأول",
          "تشرين الثاني",
          "كانون الأول",
        ],
        shortMonths: [
          "كانون الثاني",
          "شباط",
          "آذار",
          "نيسان",
          "أيار",
          "حزيران",
          "تموز",
          "آب",
          "أيلول",
          "تشرين الأول",
          "تشرين الثاني",
          "كانون الأول",
        ],
        days: [
          "الأحد",
          "الأثنين",
          "الثلاثاء",
          "الأربعاء",
          "الخميس",
          "الجمعة",
          "السبت",
        ],
        shortDays: [
          "الأحد",
          "الأثنين",
          "الثلاثاء",
          "الأربعاء",
          "الخميس",
          "الجمعة",
          "السبت",
        ],
        toolbar: {
          exportToSVG: "تحميل ملف SVG",
          exportToPNG: "تحميل صورة PNG",
          menu: "القائمة",
          selection: "تحديد",
          selectionZoom: "تكبير التحديد",
          zoomIn: "تكبير",
          zoomOut: "تصغير",
          pan: "تثبيت",
          reset: "إعادة تعيين",
        },
      },
    },
  ],
  defaultLocale: "ar",
};

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
  },
  mutations: {
    SET_BANKS: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    SET_SERVICE_BANKS: (state, data) => (state.list = data),
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(`visitor/banks?page=${page}`);
      commit("SET_BANKS", payload);
    },
    async service({ commit }, service) {
      const { payload } = await $http.get(
        `visitor/service/banks?service=${service}`
      );
      commit("SET_SERVICE_BANKS", payload);
    },
  },
};

<template>
  <ValidationProvider
    v-show="show"
    v-slot="{ errors }"
    :vid="randomName"
    class="form-group"
    :name="label"
    :disabled="disabled"
    :rules="rules"
    tag="div"
    mode="eager"
  >
    <div class="form-group custom-time">
      <label v-if="label">{{ label }}</label>
      <input
        v-model="field"
        type="text"
        :placeholder="placeholder"
        :disabled="isDisabled"
        :readonly="readonly"
        class="form-control"
        v-on="{
          ...$listeners,
          input: (e) => e.target.value,
        }"
        @click="showMenu"
      />
      <small class="text-danger">{{ errors[0] }}</small>
      <div
        v-if="menu"
        v-click-outside="() => (menu = false)"
        class="custom-time__menu"
      >
        <div class="custom-time__header">
          <span>الساعة</span>
          <span>الدقيقة</span>
          <span>الثانية</span>
          <span>الوقت</span>
        </div>
        <div class="custom-time__body">
          <div class="custom-time__items">
            <ul>
              <li
                v-for="i in 12"
                :key="i"
                :class="{ active: ans(i) === hour }"
                @click="hour = ans(i)"
              >
                {{ ans(i) }}
              </li>
            </ul>
          </div>
          <div class="custom-time__items">
            <ul>
              <li
                v-for="i in 59"
                :key="i"
                :class="{ active: ans(i) === minutes }"
                @click="minutes = ans(i)"
              >
                {{ ans(i) }}
              </li>
            </ul>
          </div>
          <div class="custom-time__items">
            <ul>
              <li
                v-for="i in 59"
                :key="i"
                :class="{ active: ans(i) === seconds }"
                @click="seconds = ans(i)"
              >
                {{ ans(i) }}
              </li>
            </ul>
          </div>
          <div class="custom-time__items">
            <ul>
              <li
                :class="{ active: 'صباحاً' === time }"
                @click="time = 'صباحاً'"
              >
                صباحاً
              </li>
              <li :class="{ active: 'مساءً' === time }" @click="time = 'مساءً'">
                مساءً
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import fieldMixins from "@/mixins/fieldMixins";
import { ValidationProvider } from "vee-validate";

export default {
  name: "VTime",
  components: {
    ValidationProvider,
  },
  mixins: [fieldMixins],
  data() {
    return {
      hour: "01",
      minutes: "01",
      seconds: "01",
      time: "مساءً",
      menu: false,
    };
  },
  computed: {
    getFullTime() {
      return `${this.hour}:${this.minutes}:${this.seconds} ${this.time}`;
    },
    isDisabled() {
      if (typeof this.disabled === "function") return this.disabled();
      return this.disabled;
    },
    field: {
      get() {
        return this.value;
      },
      set(value) {
        // eslint-disable-next-line no-console
        this.$emit("input", value);
      },
    },
    randomName() {
      return Math.random().toString(36).substring(7);
    },
  },
  watch: {
    getFullTime() {
      this.$emit("input", this.getFullTime);
    },
  },
  mounted() {
    if (this.value) {
      const timeSplit = this.value.split(" ");
      const times = timeSplit[0].split(":");
      this.hour = times[0];
      this.minutes = times[1];
      this.seconds = times[2];
      this.time = timeSplit[1];
    } else {
      this.$emit("input", this.getFullTime);
    }
  },
  methods: {
    ans(value) {
      return value < 10 ? `0${value}` : value;
    },
    showMenu() {
      if (this.disabled) return;
      this.menu = true;
    },
  },
};
</script>

<style lang="scss" src="./VTime.scss"></style>

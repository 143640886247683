import { extend } from "vee-validate";
import {
  regex,
  required,
  numeric,
  alpha_num,
  min,
  max,
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: (field) => `${field} مطلوب `,
});
extend("regex", {
  ...regex,
  message: (field) => `${field} غير صحيح `,
});
extend("numeric", {
  ...numeric,
  message: (field) => `${field} يجب ان يحتوي على ارقام فقط `,
});
extend("alpha_num", {
  ...alpha_num,
  message: (field) => `${field} يجب ان يحتوي على ارقام و احرف فقط `,
});
extend("max", {
  ...max,
  message: () => ` يجب ان لا يكون اكثر من ${max} `,
});
extend("min", {
  ...min,
  message: () => `يجب ان يكون اكبر من ${min} `,
});

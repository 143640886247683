<template>
  <b-modal
    id="mobile-modal"
    shadow
    hide-footer
    centered
    :no-close-on-backdrop="true"
    size="lg"
    body-class="p-5"
  >
    <b-row align-v="center">
      <b-col lg="6" md="6" class="order-2 order-md-1">
        <div class="d-flex flex-column">
          <h1 class="text-info">مصرفنا</h1>
          <p class="my-3" style="line-height: 2">
            لعرض كافة تفاصيل الخدمات والمصارف يرجى تنزل تطبيق مصارفنا
          </p>
          <ul class="d-flex flex-row p-0" style="list-style: none">
            <li class="ml-3">
              <a
                href="https://apps.apple.com/iq/app/masrafna-%D9%85%D8%B5%D8%B1%D9%81%D9%86%D8%A7/id1664131687"
                ><img
                  src="@/assets/apple-store.jpg"
                  width="120"
                  alt="Apple Store"
              /></a>
            </li>
            <li class="mx-3">
              <a
                href="https://play.google.com/store/apps/details?id=com.masrafna"
                ><img
                  src="@/assets/google-play.jpg"
                  width="120"
                  alt="Google Play"
              /></a>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col lg="6" md="6" class="text-center order-1 order-md-2">
        <img src="@/assets/mobile-app1.png" class="w-75" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "VDownloadMobile",
};
</script>

<style></style>

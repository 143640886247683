<template>
  <b-card class="my-3 p-2" header-bg-variant="transparent">
    <template #header>
      <div class="d-flex justify-content-between">
        <span>{{ title }} {{ type ? "خلال الشهر" : "خلال السنة" }} </span>
        <b-button-group class="mx-1">
          <b-button
            :variant="type ? 'info' : 'outline-info'"
            size="sm"
            @click="type = true"
            >شهر</b-button
          >
          <b-button
            :variant="!type ? 'info' : 'outline-info'"
            size="sm"
            @click="type = false"
            >السنة</b-button
          >
        </b-button-group>
      </div>
    </template>
    <apexchart
      height="400"
      :options="chartOptions"
      :series="series"
      responsize
    ></apexchart>
  </b-card>
</template>

<script>
export default {
  props: {
    title: String,
    data: Object,
  },
  data() {
    return {
      type: true,
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          selection: {
            enabled: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
        },
        xaxis: {
          xaxis: {
            type: "datetime",
            categories: this.type
              ? this.data.month?.date
              : this.data.year?.date,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          show: false,
        },
        fill: {
          colors: [
            "#FFEBC6",
            "#FF7723",
            "#CCF3FF",
            "#BBFFF2",
            "#CCD1FF",
            "#D5E5FF",
            "#FFEBC6",
            "#FF7723",
            "#FFEBC6",
            "#FF7723",
            "#CCF3FF",
            "#BBFFF2",
          ],
        },
        stroke: {
          colors: ["#ccc"],
        },
      };
    },
    series() {
      return [
        {
          name: this.type ? " خلال الشهر " : " خلال السنة ",
          data: (this.type ? this.data.month?.data : this.data.year?.data) ?? [
            0, 0, 0, 0, 0,
          ],
        },
      ];
    },
  },
};
</script>

<style></style>

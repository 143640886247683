<template>
  <b-card>
    <b-row>
      <b-col sm="2" md="4">
        <b-icon
          class="rounded-circle h-100 w-100"
          :icon="icon"
          scale="0.3"
          :style="{ 'background-color': bg, color: variant }"
        ></b-icon>
      </b-col>
      <b-col class="d-flex flex-column justify-content-center">
        <h5 class="text-primary">{{ count }}</h5>
        <br />
        <h6 class="text-muted">{{ title }}</h6>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "VStatisticsCrad",
  props: {
    title: String,
    count: Number,
    variant: String,
    bg: String,
    icon: String,
  },
};
</script>

<style lang="scss"></style>

import { $http } from "@/plugins/axios";
import users from "./modules/users";
import loans from "./modules/loans";
import funding from "./modules/funding";
import credits from "./modules/credits";
import accounts from "./modules/accounts";
import external from "./modules/external-funding";
import guarantee from "./modules/guarantee";
import services from "./modules/services";
import about from "./modules/about";
import branches from "./modules/branches";
export default {
  namespaced: true,
  modules: {
    users,
    loans,
    funding,
    credits,
    accounts,
    external,
    guarantee,
    services,
    about,
    branches,
  },
  state: {
    statistics: {
      count: {},
      bank_visits: {},
    },
  },
  mutations: {
    SET_STATISTICS: (state, data) => (state.statistics = data),
  },
  actions: {
    async get({ commit }) {
      const { payload } = await $http.get(`bank-admin/statistics`);
      commit("SET_STATISTICS", payload);
    },
  },
};

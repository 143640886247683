<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="randomName"
    class="form-group"
    :name="label"
    return="id"
    :disabled="disabled"
    :rules="rules"
    tag="div"
    mode="eager"
  >
    <label v-if="label && !hideLabel">{{ label }}</label>
    <VSelect
      v-if="multiple || searchable"
      v-model="field"
      :options="items"
      :reduce="(item) => item[itemValue]"
      :placeholder="placeholder"
      :label="itemText"
      :multiple="multiple"
      :disabled="isDisabled"
    ></VSelect>
    <select
      v-else
      ref="field"
      v-model="field"
      :class="{ 'form-control': true, 'is-invalid': errors.length }"
      :disabled="isDisabled"
    >
      <template v-if="hasItems">
        <option
          v-for="(item, index) in items"
          :key="index"
          :value="item[itemValue]"
        >
          {{ item[itemText] }}
        </option>
      </template>
      <slot v-else></slot>
    </select>
    <div class="invalid-feedback">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import fieldMixins from "@/mixins/fieldMixins";
import VSelect from "vue-select";

export default {
  name: "VSelectField",
  components: {
    ValidationProvider,
    VSelect,
  },
  mixins: [fieldMixins],
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    itemText: {
      required: false,
      default: "text",
    },
    itemValue: {
      required: false,
      default: "value",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    searchable: Boolean,
    hideLabel: Boolean,
  },
  computed: {
    hasItems() {
      return Array.isArray(this.items) && this.items.length !== 0;
    },
    isDisabled() {
      if (typeof this.disabled === "function") return this.disabled();
      return this.disabled;
    },
    field: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    randomName() {
      return Math.random().toString(36).substring(7);
    },
  },
  watch: {
    value() {
      this.field = this.value;
    },
  },
};
</script>

<style src="vue-select/dist/vue-select.css"></style>

<style>
.v-select .vs__dropdown-toggle {
  background: #ffffff !important;
}
.v-select .vs__selected {
  position: absolute;
  top: -5px;
}
.vs__clear {
  margin-left: 8px;
}
.vs__open-indicator {
  margin-top: 5px;
  margin-left: 5px;
}
</style>

/* eslint-disable prettier/prettier */
import { $http } from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        token: localStorage.token,
    },
    mutations: {
        SET_AUTH: (state, data) => {
            localStorage.setItem("token", data.access_token.token);
            localStorage.setItem("image", JSON.stringify(data.user.image));
            localStorage.setItem("name", JSON.stringify(data.user.name));
            localStorage.setItem("role", JSON.stringify(data.user.role));
            state.token = data.access_token.token;
        }
    },
    actions: {
        async login({ commit }, credentials) {
            const { payload } = await $http.post("login", credentials);
            commit("SET_AUTH", payload);
        },
    }
};

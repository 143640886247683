/* eslint-disable prettier/prettier */
import { $http } from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        form: {
            terms_and_conditions: null,
            legal_framework: null,
            procedures: null,
            about: null,
        }

    },
    mutations: {
        SET_GUARANTEE: (state, data) => {
            state.form = data.letters_of_guarantee ? data.letters_of_guarantee : data;
        }
    },
    actions: {
        async get({ commit }, id) {
            const { payload } = await $http.get(`bank-admin/letters-of-guarantee?type=${id}`);
            commit("SET_GUARANTEE", payload);
        },
        async update({ state }, id) {
            return await $http.post(`bank-admin/letters-of-guarantee?type=${id}`, state.form);
        },
    }
};

import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
    form: {
      id: null,
      image: null,
      title: null,
      content: null,
    },
  },
  mutations: {
    SET_NEWS: (state, data) => Object.assign(state.form, data),
    STORE_NEWS: (state, data) => state.list.push(data),
    SET_NEWS_LIST: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    UPDATE_NEWS: (state, data) => {
      state.list = state.list.filter((item) => item.id !== data.id);
      state.list.unshift(data);
    },
    DELETE_NEWS: (state, data) => {
      const item = state.list.find((item) => item.id === data);
      state.list.splice(state.list.indexOf(item), 1);
    },
  },
  getters: {
    news: (state) => {
      let news = new FormData();
      news.append("image", state.form.image);
      news.append("title", state.form.title);
      news.append("content", state.form.content);
      return news;
    },
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(`admin/news?page=${page}`);
      commit("SET_NEWS_LIST", payload);
    },
    async show({ commit }, id) {
      const { payload } = await $http.get(`admin/news/${id}`);
      commit("SET_NEWS", payload);
    },
    async store({ getters }) {
      return await $http.post("admin/news", getters.news);
    },
    async update({ state, getters }) {
      return await $http.post(`admin/news/${state.form.id}`, getters.news);
    },
    async delete(_, id) {
      return await $http.delete(`admin/news/${id}`);
    },
  },
};

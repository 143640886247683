<template>
  <div class="m-5">
    <div
      class="d-inline-flex w-100 align-items-center justify-content-center my-5"
      style="cursor: pointer"
      @click="goTo()"
    >
      <h1 class="text-info text-center ml-3 mb-0">
        {{ title }}
      </h1>
      <b-icon icon="grid1x2" variant="info" scale="2.4"></b-icon>
    </div>
    <b-row>
      <b-col
        lg="4"
        md="6"
        v-for="item in list.slice(0, 3)"
        :key="item.id"
        class="mb-3"
      >
        <VBlog :item="item" :category="categories"></VBlog>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    list: Array,
    categories: Number,
  },
  methods: {
    goTo() {
      this.title.includes("المقالات")
        ? this.$router.push("/articles")
        : this.$router.push("/news");
    },
  },
};
</script>

<style lang="scss"></style>

export const decodeToken = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    throw new Error("Token is not valid!");
  }
};
export const cloneObject = (target, source) => {
  if (
    !source ||
    !target ||
    typeof source !== "object" ||
    typeof target !== "object"
  )
    throw new TypeError("Invalid argument");
  for (const p in source)
    if (source[p])
      if (source[p] && typeof source[p] === "object")
        if (target[p] && typeof target[p] === "object")
          cloneObject(target[p], source[p]);
        else target[p] = source[p];
      else target[p] = source[p];
};

import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
    form: {
      id: null,
      cover: null,
      logo: null,
      name: null,
      phone: null,
      email: null,
      type: 0,
      location: null,
      website: null,
      about: null,
    },
    types: [
      { value: 0, text: "أسلامي" },
      { value: 1, text: "تجاري" },
    ],
  },
  mutations: {
    SET_BANK: (state, data) => Object.assign(state.form, data),
    STORE_BANK: (state, data) => state.list.unshift(data),
    SET_BANKS: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    UPDATE_BANK: (state, data) => {
      state.list = state.list.filter((item) => item.id !== data.id);
      state.list.unshift(data);
    },
    DELETE_BANK: (state, data) => {
      const item = state.list.find((item) => item.id === data);
      state.list.splice(state.list.indexOf(item), 1);
    },
  },
  getters: {
    bank: (state) => {
      let bank = new FormData();
      bank.append("cover", state.form.cover);
      bank.append("logo", state.form.logo);
      bank.append("name", state.form.name);
      bank.append("email", state.form.email);
      bank.append("location", state.form.location);
      bank.append("website", state.form.website);
      bank.append("about", state.form.about);
      bank.append("type", state.form.type);
      bank.append("phone", state.form.phone);
      return bank;
    },
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(`admin/banks?page=${page}`);
      commit("SET_BANKS", payload);
    },
    async getAll() {
      const { payload } = await $http.get("admin/all-banks");
      return payload;
    },
    async show({ commit }, id) {
      const { payload } = await $http.get(`admin/banks/${id}`);
      commit("SET_BANK", payload);
    },
    async store({ getters }) {
      return await $http.post("admin/banks", getters.bank);
    },
    async update({ state, getters }) {
      return await $http.post(`admin/banks/${state.form.id}`, getters.bank);
    },
    async delete(_, id) {
      return await $http.delete(`admin/banks/${id}`);
    },
  },
};

import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import loader from "./modules/loader";
import admin from "./modules/admin/";
import banks from "./modules/banks/";
import reviewer from "./modules/reviewer/";
import website from "./modules/website/";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    loader,
    admin,
    banks,
    reviewer,
    website,
  },
});

import { $http } from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    list: [],
    links: {},
    meta: {},
    form: {
      id: null,
      type: 0,
      name: null,
      about: null,
      limits: null,
      benefit: null,
      period: null,
      warranties: null,
      conditions: null,
      mechanism: null,
      payment_mechanism: null,
    },
    types: [
      { value: 0, text: "قروض أفراد" },
      { value: 1, text: "قروض شركات" },
      { value: 2, text: "قروض آخرى" },
    ],
  },
  mutations: {
    SET_FUNDING: (state, data) => Object.assign(state.form, data),
    STORE_FUNDING: (state, data) => state.list.unshift(data),
    SET_FUNDINGS: (state, data) => {
      state.list = data.data;
      state.links = data.links;
      state.meta = data.meta;
    },
    UPDATE_FUNDING: (state, data) => {
      state.list = state.list.filter((item) => item.id !== data.id);
      state.list.unshift(data);
    },
    DELETE_FUNDING: (state, data) => {
      const item = state.list.find((item) => item.id === data);
      state.list.splice(state.list.indexOf(item), 1);
    },
  },
  actions: {
    async get({ commit }, page) {
      const { payload } = await $http.get(`bank-admin/fundings?page=${page}`);
      commit("SET_FUNDINGS", payload);
    },
    async show({ commit }, id) {
      const { payload } = await $http.get(`bank-admin/fundings/${id}`);
      commit("SET_FUNDING", payload);
    },
    async store({ state }) {
      return await $http.post("bank-admin/fundings", state.form);
    },
    async update({ state }) {
      return await $http.put(
        `bank-admin/fundings/${state.form.id}`,
        state.form
      );
    },
    async delete(_, id) {
      return await $http.delete(`bank-admin/fundings/${id}`);
    },
  },
};

export default [
  {
    path: "statistics",
    name: "أحصائيات المصرف",
    component: () => import("@/pages/Bank/Statistics/index"),
  },
  {
    path: "users",
    name: "مستخدمي المصرف",
    component: () => import("@/pages/Bank/Users/index"),
  },
  {
    path: "loans",
    name: "القروض",
    component: () => import("@/pages/Bank/Loans/index"),
  },
  {
    path: "funding",
    name: "التمويلات",
    component: () => import("@/pages/Bank/Funding/index"),
  },
  {
    path: "credit",
    name: "البطاقات",
    component: () => import("@/pages/Bank/CreditCard/index"),
  },
  {
    path: "accounts",
    name: "حسابات الودائع",
    component: () => import("@/pages/Bank/Accounts/index"),
    redirect: "accounts/saving",
    children: [
      {
        path: "saving",
        name: "حسابات التوفير والأدخار",
        meta: {
          id: 0,
        },
        component: () => import("@/pages/Bank/Accounts/index"),
      },
      {
        path: "fixed",
        name: "حسابات الودائع الثابته",
        meta: {
          id: 1,
        },
        component: () => import("@/pages/Bank/Accounts/index"),
      },
      {
        path: "time",
        name: "حسابات الودائع الوقتية",
        meta: {
          id: 2,
        },
        component: () => import("@/pages/Bank/Accounts/index"),
      },
      {
        path: "current",
        name: "حسابات جارية",
        meta: {
          id: 3,
        },
        component: () => import("@/pages/Bank/Accounts/index"),
      },
      {
        path: "deposit",
        name: "شهادة الأيداع",
        meta: {
          id: 4,
        },
        component: () => import("@/pages/Bank/Accounts/index"),
      },
    ],
  },
  {
    path: "external-funding",
    name: "التمويلات الخارجية",
    component: () => import("@/pages/Bank/ExternalFunding/index"),
    redirect: "external-funding/documentary",
    children: [
      {
        path: "documentary",
        name: "الأعتمادات المستندية",
        meta: {
          id: 0,
        },
        component: () => import("@/pages/Bank/ExternalFunding/index"),
      },
      {
        path: "external",
        name: "الحوالات الخارجية",
        meta: {
          id: 1,
        },
        component: () => import("@/pages/Bank/ExternalFunding/index"),
      },
    ],
  },
  {
    path: "guarantee",
    name: "خطابات الضمان",
    component: () => import("@/pages/Bank/Guarantee/index"),
    redirect: "guarantee/internal",
    children: [
      {
        path: "internal",
        name: "الداخلية",
        meta: {
          id: 0,
        },
        component: () => import("@/pages/Bank/Guarantee/index"),
      },
      {
        path: "external",
        name: "الخارجية",
        meta: {
          id: 1,
        },
        component: () => import("@/pages/Bank/Guarantee/index"),
      },
    ],
  },
  {
    path: "services",
    name: "خدمات آخرى",
    component: () => import("@/pages/Bank/OtherServices/index"),
    redirect: "services/instruments",
    children: [
      {
        path: "instruments",
        name: "الصكوك المصدقة والسفاتج",
        meta: {
          id: 0,
        },
        component: () => import("@/pages/Bank/OtherServices/index"),
      },
      {
        path: "investments",
        name: "الأستثمارات",
        meta: {
          id: 6,
        },
        component: () => import("@/pages/Bank/OtherServices/index"),
      },
      {
        path: "balance",
        name: "صكوك المقاصة الالكترونية",
        meta: {
          id: 1,
        },
        component: () => import("@/pages/Bank/OtherServices/index"),
      },
      {
        path: "incorporations",
        name: "تأسيس الشركات والاكتتاب",
        meta: {
          id: 2,
        },
        component: () => import("@/pages/Bank/OtherServices/index"),
      },
      {
        path: "statements",
        name: "كشوفات الحساب",
        meta: {
          id: 3,
        },
        component: () => import("@/pages/Bank/OtherServices/index"),
      },
      {
        path: "swift",
        name: "SWIFT الحوالات الخارجية",
        meta: {
          id: 4,
        },
        component: () => import("@/pages/Bank/OtherServices/index"),
      },
      {
        path: "rtgs",
        name: "RTGS الحوالات الداخلية",
        meta: {
          id: 5,
        },
        component: () => import("@/pages/Bank/OtherServices/index"),
      },
    ],
  },
  {
    path: "about",
    name: "حول المصرف",
    component: () => import("@/pages/Bank/About/index"),
    redirect: "about/resettlement",
    children: [
      {
        path: "resettlement",
        name: "التوطين",
        meta: {
          id: 0,
        },
        component: () => import("@/pages/Bank/About/index"),
      },
      {
        path: "online",
        name: "خدمات عبر الأنترنت",
        meta: {
          id: 1,
        },
        component: () => import("@/pages/Bank/About/index"),
      },
      {
        path: "westernUnion ",
        name: "ويسترن يونيون",
        meta: {
          id: 2,
        },
        component: () => import("@/pages/Bank/About/index"),
      },
    ],
  },
  {
    path: "departments",
    name: "اقسام المصرف",
    component: () => import("@/pages/Bank/Branches/index"),
    redirect: "departments/atms",
    children: [
      {
        path: "atms",
        name: "ATMS",
        meta: {
          id: 0,
        },
        component: () => import("@/pages/Bank/Branches/index"),
      },
      {
        path: "pos ",
        name: "POS",
        meta: {
          id: 1,
        },
        component: () => import("@/pages/Bank/Branches/index"),
      },
      {
        path: "branches",
        name: "فروع المصرف",
        meta: {
          id: 2,
        },
        component: () => import("@/pages/Bank/Branches/index"),
      },
    ],
  },
];

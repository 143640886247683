export default {
  namespaced: true,
  state: {
    requests: [],
  },
  mutations: {
    ADD_REQUEST: (state, data) => state.requests.push(data),
    REMOVE_REQUEST: (state, url) => {
      state.requests = state.requests.filter((r) => r.url !== url);
    },
    REMOVE_ALL_REQUESTS: (state) => (state.requests = []),
  },
  actions: {
    add({ commit }, { url, cancelToken }) {
      commit("ADD_REQUEST", { url, cancelToken });
    },
    remove({ commit }, url) {
      commit("REMOVE_REQUEST", url);
    },
    cancelAll({ state, commit }) {
      state.requests.forEach((req) => {
        if (req.cancelToken.cancel)
          req.cancelToken.cancel(
            "request canceled: user navigated to another route "
          );
      });
      commit("REMOVE_ALL_REQUESTS");
    },
  },
};

<template>
  <ValidationObserver ref="form" v-slot="{ invalid }" tag="form">
    <form>
      <div class="row">
        <template v-for="(input, index) in schema">
          <GroupTitle
            v-if="input.type === 'title'"
            v-show="!input.hide"
            :key="index"
            >{{ input.text }}</GroupTitle
          >
          <FieldWrapper
            v-else
            :key="index"
            :input="input"
            :value="getValue(input.model)"
            @input="set(form, input.model, $event)"
          ></FieldWrapper>
        </template>
      </div>
    </form>
    <slot :invalid="invalid"></slot>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import FieldWrapper from "./FieldWrapper";
import GroupTitle from "./GroupTitle";
export default {
  name: "FormGenerator",
  components: {
    GroupTitle,
    FieldWrapper,
    ValidationObserver,
  },
  props: {
    schema: {
      type: Array,
      default: () => {
        return [];
      },
    },
    default: Boolean,
    value: {},
  },
  data() {
    return {
      form: {},
    };
  },
  watch: {
    form: {
      handler() {
        if (this.default) return;
        this.$emit("input", this.form);
      },
      deep: true,
    },
    value: {
      handler() {
        this.form = this.value;
      },
      deep: true,
    },
  },
  mounted() {
    this.form = this.default ? this.value : this.setFormData();
  },
  methods: {
    set(obj, path, value) {
      // eslint-disable-next-line no-console
      if (Object(this.form) !== this.form) return this.form;
      if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
      path
        .slice(0, -1)
        .reduce(
          (a, c, i) =>
            Object(a[c]) === a[c]
              ? a[c]
              : (a[c] = Math.abs(path[i + 1]) >> 0 === +path[i + 1] ? [] : {}),
          obj
        )[path[path.length - 1]] = value;
      return obj;
    },
    getValue(path) {
      if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
      const result = path
        .slice(0, -1)
        .reduce(
          (a, c, i) =>
            Object(a[c]) === a[c]
              ? a[c]
              : (a[c] = Math.abs(path[i + 1]) >> 0 === +path[i + 1] ? [] : {}),
          this.form
        )[path[path.length - 1]];
      return result === undefined ? null : result;
    },
    setFormData() {
      const filteredSchema = this.schema.filter((s) => s.model !== undefined);
      return filteredSchema.reduce(
        (o, { model }) => this.set(o, model, null),
        {}
      );
    },
    async validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped></style>

import Auth from "./lib/auth";

function install(Vue, options) {
  if (!options.router)
    throw new Error("You must supply a router instance in the options");
  const authHelper = new Auth(options.router, options);
  authHelper.secure();
  Vue.mixin({
    computed: {
      userRole() {
        return authHelper.userRole;
      },
    },
  });
}
export default install;
